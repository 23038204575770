/**
 * @generated SignedSource<<2de3614a753e857b77338fd777750418>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSearchResultContainer_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedProductAdWrapper_itemSearch" | "SharedItemTile_itemSearch" | "useMeasurementUnit_itemSearch" | "useMergedSbAndSponsoredItems_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "SbRespSearchResultContainer_itemSearch";
};
export type SbRespSearchResultContainer_itemSearch$key = {
  readonly " $data"?: SbRespSearchResultContainer_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSearchResultContainer_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedProductAdWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergedSbAndSponsoredItems_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "6ab7c4beffe4eb15c59b7eded45157da";

export default node;
